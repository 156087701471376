import InputSelect, { InputSelectProps } from '../input/InputSelect';
import { useEffect } from 'react';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';

export type ZodFieldSelectProps = Omit<InputSelectProps, 'onChange' | 'name'> & {
  name: string;
  clearOnUnmount?: boolean;
};

export default function ZodFieldSelect(props: ZodFieldSelectProps) {
  const [name, value, setValue] = useZodFormFieldSingle(props.name);

  useEffect(() => {
    const enabled = props.options.filter((o) => o.disabled !== true);

    const enabledValue = enabled[0] && 'id' in enabled[0] ? enabled[0].id : enabled[0]?.value;

    if (
      enabledValue &&
      ((enabled.length === 1 && enabledValue !== value) ||
        (!props.placeholder && !value) ||
        (value && !enabled.some((e) => ('id' in e ? e.id : e.value) === value)))
    ) {
      setValue(enabledValue.toString());
    }
  }, [props.options, value]);

  useEffect(() => {
    return () => {
      if (props.clearOnUnmount) {
        setValue('');
      }
    };
  }, [props.clearOnUnmount]);

  return <InputSelect {...props} name={name} value={value} onChange={(value) => setValue(value || '')} />;
}
